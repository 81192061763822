import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, catchError } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PlanoCurso } from 'src/app/models/plano-curso';
import { Login } from 'src/app/models/login';
import { ConfirmacaoUsuarioSenha } from 'src/app/models/confirmacao-usuario-senha';
import { LoginResponse } from 'src/app/models/login-response';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(login: Login): Observable<LoginResponse> {
    return this.http.post(`${environment.baseApiUrl}/auth/login`, login).pipe(
      map((auth: any) => {
        if (auth) {
          return auth;
        }
        return null;
      }),
      catchError((e) => throwError(e))
    );
  }

  refreshToken() {
    const refreshToken = localStorage.getItem('refreshToken');
    return this.http.post(`${environment.baseApiUrl}/auth/refresh`, { refreshToken }).pipe(
      map((response) => {
        localStorage.setItem('token', response['token']);
        localStorage.setItem('refreshToken', response['refreshToken']);
      })
    );
  }

  getPlanos() {
    const planos = JSON.parse(localStorage.getItem('planos'));
    return planos;
  }

  getPlanoSelecionado(): any {
    let plano = {};
    const item = localStorage.getItem('planoSelecionado');
    if (item) {
      plano = Object.assign(new PlanoCurso(), JSON.parse(item));
    }
    return plano;
  }

  recuperarSenha(email: string) {
    return this.http.post(`${environment.baseApiUrl}/auth/recuperacao-senha`, { email });
  }

  cadastrarNovaSenha(confirmacaoUsuarioSenha: ConfirmacaoUsuarioSenha) {
    return this.http.post(`${environment.baseApiUrl}/auth/nova-senha`, confirmacaoUsuarioSenha);
  }
}
