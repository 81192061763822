const urlId = new URLSearchParams(window.location.search);

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.


// PROVISÓRIO (por enquanto, será o mesmo que o ambiente de prod):
export const environment = {
  production: false,
  baseApiUrl: 'https://api-medaula-dev.azurewebsites.net/api/medaula',
  baseUrl: 'https://api-medaula-dev.azurewebsites.net/api',
  baseUrlImage: 'https://api-medaula-dev.azurewebsites.net',
  medAulaId: urlId.get('source')
};

// export const environment = {
//   production: false,
//   baseApiUrl: 'https://localhost:5001/api/medaula',
//   baseUrl: 'https://localhost:5001',
//   medAulaId: 1
// };



// export const environment = {
//   production: true,
//   baseApiUrl: 'https://localhost:65508/api/medaula',
//   baseUrl: 'https://localhost:65508/',
//   medAulaId: urlId.get('source')
// };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
